




















import Vue from 'vue';
import Component from 'vue-class-component';
import LangSelector from '@/components/LangSelector.vue';

@Component({
  name: 'AuthContainer',
  components: {
    LangSelector
  },
})
export default class AuthContainer extends Vue {

}
